import { Head } from 'components/common';
import { RewardService } from 'components/policy';
import PolicyLayout from 'layout/policy-layout';

const PolicyReward = () => {
  return (
    <PolicyLayout>
      <Head title="서비스 이용 약관(리워드 광고 매체 제휴)" />
      <RewardService />
    </PolicyLayout>
  );
};

export default PolicyReward;
